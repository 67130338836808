.low-label-ui {
  background-color: #ffce61 !important;
  height: 8px;
  width: 8px;
  margin-right: 9px;
}
.low_label {
  visibility: hidden;
}
.medium {
  .low-label-ui {
    background-color: #51cd92 !important;
    height: 8px;
    width: 8px;
    margin-right: 9px;
  }
  .low_label {
    visibility: hidden;
  }
}

.high {
  .low-label-ui {
    background-color: #f14b7e !important;
    height: 8px;
    width: 8px;
    margin-right: 9px;
  }
  .low_label {
    visibility: hidden;
  }
}
.bg-light-blue-actions {
  background-color: #f1faff !important;
}
.avengersInitiatives-table {
  tr {
    th,
    td {
      white-space: nowrap !important;
    }
  }
}
.border-grey-bd {
  .card {
    border: 1px solid #ECECEC !important;
    margin-bottom: 10px;
    margin-top: 10px;
    // width: 50%;
    width: 49% !important;
    padding: 20px 0;

    // height: 120px;
    min-height: 200px;

    @media (max-width:768px){
      &{
        width: 100% !important;
      }
    }
  }
}

.color-purple {
  background-color: #7239ea !important;
}
.color-powder-blue {
  background-color: #0991de !important;
}
.color-orange-red {
  background-color: #f14b7e !important;
}

.color-yellow {
  background-color: #ffce61 !important;
}

.color-green {
  background-color: #51cd92 !important;
}
.initiatives_tags {
  border-radius: 7px !important;
  padding: 2px 8px !important;
}

.bg-light-blue {
  @extend .initiatives_tags;
  background: #f1faff !important;
}
.text-light-blue {
  color: #00a1f7;
}
.bg-light-yellow {
  @extend .initiatives_tags;
  background-color: #fff8dd !important;
}
.text-light-yellow {
  color: #ffce61;
}
.bg-light-green {
  @extend .initiatives_tags;
  background-color: #e8fff3 !important;
}
.text-light-green {
  color: #51cd92;
}

.top-12{
  top: -12px;
}
.card-dir-list{
  @media (max-width:768px){
 &{
  flex-direction: column !important;
 }
  }
}

.legend-card-main{
  table {
    tbody {
      tr {
        td {
          &[data-label='Assignee'] {
            .textEllipsis {
              width: 140px;
              display: block;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -webkit-line-clamp: 1;
              @media screen and (max-width: 576px){
                width: 120px;
              }
            }
          }
        }
      }
    }
  }
}