//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

.page-body {
    padding: 30px;
}

.btn-blue {
    &:hover {
        background-color: #073b66 !important;
    }
}

.card-label {
    // width: 180px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
}

.cancel_button {
    color: grey;
    padding: 7px 30px !important;

    &:hover {

        background: #F5F5F7;
        // padding: 4px 23px !important;
        // height: 35px;
        // font-size: 12px;
        color: #F14B7E;
    }
}

.link_button {

    color: #0368cd;

    &:hover {
        color: #004B87 !important;
    }


}

.link_bg {
    &:hover {
        background-color: #004B87 !important;
    }
}

.task_pop_up_activity {
    .card-body {
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
    }
}

.btn-light-primary {
    &:hover {
        background-color: #0368cd !important;
        color: white !important;
    }
}


.dzu-dropzone {
    margin-top: 0 !important;
    border: 1px solid #f5f5f7;
    min-height: 60px;
    border-radius: 0 !important;

}

.icon_hover {
    &:hover {
        fill: #004B87 !important;
    }
}

.create_icon {
    height: 35px;
    width: 35px;
    border-radius: 50px !important;
    background: rgb(3, 104, 205);
    color: white;
    font-weight: 100;
    border: 1px solid transparent !important;

    &:hover {
        background-color: #004B87 !important;
    }
}

.bg-hover-grey {
    &:hover {
        background-color: #f3f3f3 !important;
    }
}

.btn-light-primary {
    background-color: #F9F9F9 !important;
}

.badge-light-primary {
    color: #0368CD !important;
    background-color: #f1faff !important;
    // padding: 12px 22px !important;
    font-weight: 500;

}

.badge-light {
    padding: 12px 22px !important;
    // font-size: 1.075rem !important;
    font-weight: 500;
}

.alignment_mobile {
    justify-content: end;
    align-items: baseline;

    & {
        @media (max-width:768px) {
            justify-content: center !important;
        }
    }
}

// .margin_btm_mob{
//     @media (max-width:768px) {
//        margin-bottom: 23px;
// }
// }
.form-control.form-control-solid {
    font-weight: 500;
}

.dot_ui {
    background: #00A1F7 !important;
    height: 11px !important;
    width: 11px !important;

    top: 12px !important;
    left: 64% !important;

    @media (max-width:425px) {
        background: #00A1F7 !important;
        height: 8px !important;
        width: 8px !important;
        top: 8px !important;
        left: 63% !important;
    }
}

.menu_ellipse {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 173px !important;
}

.mh-80 {
    height: 80vh;

    @media screen and (max-width:576px) {
        height: 75vh;
    }
}

.overflow_height_scroll {
    height: 65vh;
    overflow-y: scroll;

    @media screen and (max-width:576px) {
        height: 60vh;
    }
}

.overflow_height_scroll_teams {
    height: 60vh;
    overflow-y: scroll;

    @media screen and (max-width:576px) {
        height: 55vh;
    }
}

.overflow_height_scroll_newspace::-webkit-scrollbar-thumb,
.overflow_height_scroll::-webkit-scrollbar-thumb {
    background: #E3E4E9;

    &:hover {
        background: #A1A5B6;
    }
}

.overflow_height_scroll_newspace {
    height: 80vh;
    overflow-y: scroll;

    @media screen and (max-width:576px) {
        height: 60vh;
    }
}

.text_color_dis {
    color: #656776;
}

.rounded-border-text {
    border-radius: 0px 22px 22px 22px !important;
    margin-left: 44px;
}

.trans {
    transform: rotate(90deg);
}

.bg-light-blue {
    background-color: #E5F8FF !important;
}

.w_300px {

    // width: 300px;
    span {
        strong {
            color: #004b87 !important;
            font-weight: 500 !important;
        }
    }
}

.w_100px {

    span {
        strong {
            color: #004b87 !important;
        }
    }
}

.table_solid {
    tr {
        border-bottom: 1px solid #e4e6ef !important;
    }

}

.active_notification {
    border-left: 5px solid #34C8EE !important;
}

.border_radius_11px {
    border-radius: 11px !important;
}

// .border_notification{
//     border: 1px solid ;
// }
.border_bottom_notification {
    border-bottom: 1px solid #00000017;
    border-radius: 0 !important;
}

.border-count {
    border: 1px solid #34C8EE !important;
    border-radius: 11px !important;
    color: #34C8EE;
    padding: 0px 14px;
}

.border_notification {
    border: 1px solid #0368cd;
    /* padding: 0 !important; */
    margin: 0;
    /* display: flex; */
    text-align: center;
    margin-right: 6px;
    border-radius: 37px;
    padding: 3px 3px 4px 3px;
    /* height: 19px; */
    /* width: 24px; */
    line-height: 8px;

    .link_button {
        display: contents;
        font-size: 8px;
        color: #0368cd;

        &:hover {
            color: white !important;
        }
    }

    &:hover {
        background-color: #004B87 !important;
        border: 1px solid transparent;

        .link_button {

            color: white;
        }
    }
}

.styles {
    z-index: 105;
    position: absolute !important;
    inset: 0px 0px auto auto !important;
    margin: 0px !important;
    transform: translate3d(-30px, 72px, 0px) !important;
}

.btn_notification {
    &:hover {
        .border_notification {
            background-color: #004B87 !important;
            border: 1px solid transparent !important;

            .link_button {
                color: white !important;
            }
        }

        .link_button {
            color: #004B87 !important;
        }
    }
}

.css-1okebmr-indicatorSeparator,
.css-109onse-indicatorSeparator {
    display: none !important;
}

.general_selector {

    .css-1s2u09g-control,
    .css-1insrsq-control {
        background-color: #f9f9f9 !important;
        border-color: #f9f9f9 !important;
        height: 45px !important;
        padding: 0 3px !important;
        border-radius: 4px !important;

        &:focus-visible {
            border-color: transparent !important;
            box-shadow: none !important;
            outline: none !important;
        }
    }

    .css-14el2xx-placeholder,
    .css-lr9fot-singleValue {
        font-size: 14px !important;
        font-weight: 500 !important;
    }

}

.css-319lph-ValueContainer,
.css-b62m3t-container {
    &:focus-visible {
        border-color: transparent !important;
        box-shadow: none !important;
        outline: none !important;
    }
}

.css-1pahdxg-control {
    border-color: transparent !important;
    box-shadow: none !important;
    outline: none !important;
    background-color: #f9f9f9 !important;
    border-color: #f9f9f9 !important;
}

.line-clamp {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
}

.heading_overflow_control {
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.textEllipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
.link_hover{
    &:hover{
        color: #033d76 !important;
    }
}