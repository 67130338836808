.view_all_dashboard {
  justify-content: center;
  border: 1px solid #f5f5f7;
  padding: 16px 0;
  border-radius: 5px;
  background: #f5f5f7;
  height: 100px;
  width: 100px;
  &:hover{
    background-color: #f3f3f3;
  }
  .color-grey-dark {
    color: #a1a5b7;
  }
  p {
    font-size: 14px;
  }
}
.spaces_header {
  .bd-right-none {
    border-right: 0 !important;
    border-bottom-left-radius: 4px;
  }
  .bd-left-none {
    border-left: 0 !important;
  }
  .input-group-text {
    i {
      font-size: 16px !important;
    }
  }
  .form_control_space {
    border: 1px solid #eeeeee;
    // border-radius: 5px !important;
    color: #c2c2c2;
    &:focus {
      border: 1px solid #eeeeee;
    }
    &::placeholder {
      color: #c2c2c2 !important;
      font-size: 12px !important;
    }
  }
  .input-group {
    width: 55% !important;
  }
  img {
    width: 14px !important;
  }
  .ligh-btn {
    height: 26.94px;
    margin-left: 15px;
  }
}

.fw-500 {
  font-weight: 500 !important;
}
.header_spaces {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 14px;
}
.add_btn_spaces {
  align-items: center;
  div {
    border: 1px solid #c9cbd9;
    border-radius: 5px;
    img {
      width: 75px;
    }
  }
}

.modal_aligned_right {
  right: -381px;
  top: 142px;
}

.dashboard_space {
  .col-1 {
    margin: 0 4px;
  }
  align-items: flex-start;
  .image_ui {
    width: 100%;
    // padding: 15px;
  }
  .para_ui {
    font-size: 14px;
    white-space: nowrap;
    margin-top: 9px;
    font-weight: 500;
  }
}
.dropdown_dialog {
  transform: unset !important;
  position: absolute !important;
  top: 0 !important;
  height: 100% !important;
  .fa-times:before {
    font-size: 17px;
  }
}
.input_dashboard {
  .form-check-input {
    width: 1rem !important;
    height: 1rem !important;
  }
  .form-check {
    display: flex !important;
    align-items: center;
  }
  .form-check-label {
    margin-left: 6px;
    font-size: 12px !important;
  }
}
.dashboard_select {
  background-color: #f9f9f9;
  border-color: #f9f9f9;
  color: #a1a5b7;
  transition: color 0.2s ease, background-color 0.2s ease;
  font-size: 12px !important;
}
.f-13px {
  font-size: 12px !important;
  font-weight: 500;
}
.border-radius-0px {
  border-radius: 0px !important;
}
.border-radius-15px {
  border-radius: 15px !important;
}
.height_min {
  min-height: 660px;
  position: relative;
}
.icon_ui {
  .noUi-tooltip {
    box-shadow: 0px 0px 50px 0px rgb(82 63 105 / 15%);
    background: transparent;
    color: black;
    font-size: 0.925rem;
    border: 0;
    padding: 0.2rem 0.75rem;
    border-radius: 0.475rem;
    font-size: 10px;
  }
  .noUi-target.noUi-sm .noUi-handle {
    width: 14px !important;
    height: 14px !important;
    top: -4px !important;
  }
  .noUi-target.noUi-horizontal .noUi-handle {
    background: #0368cd;
    cursor: pointer;
  }
}
.popup_setting {
  .btn-primary {
    background-color: #0368cd !important;
  }
}
.btn-focus-white {
  &:focus {
    background-color: transparent !important;
  }
  &:hover {
    background-color: transparent !important;
  }
  &.active {
    background-color: transparent !important;
  }
}
.text-blue {
  color: #0368cd !important;
}

.card_create {
  min-height: 179.94px !important;
}
.bg-lighter {
  background-color: #f9f9f9;
}
.cards-wrapper{
  margin-left: -10px!important;
  margin-right: -10px!important;
  > div {
    margin-right: 0!important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
.form-check-input:checked {
  background-color: #0368cd !important;
  border-color: #0368cd !important;
}
.masonry-title-memeber{
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  width: 80px !important;
}
.todo-card-title-memeber,
.masonry-card-title-memeber
{
  // display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 150px !important;
}

.fs-26px{
  font-size: 22px;
}

@media screen and (max-width: 425px) {
  .spaces_header {
    flex-wrap: wrap;
    .input-group {
      width: 100% !important;
    }
  }
  .header_spaces{
    align-items: baseline;
  }
}
.margins-dashboard{
  margin-right: 3.5rem !important;
  margin-left: 3.5rem !important;
  
  @media (max-width:540px) {
    &{
     
      margin-right: 0.5rem !important;
      margin-left: 0.5rem !important;
    }
  }
    @media (max-width:398px) {
      &{
       justify-content: center;
        margin-right: 0.5rem !important;
        margin-left: 0.5rem !important;
      }
    
  }
}
.padding_bottom_header{
  h2{
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;  
  }
}
@media (max-width:575px){
  .padding_bottom_header{
    padding-bottom: 12px;
  }
  .px-sm-12{
    .row{
      padding-bottom: 12px;
    }
  }
}